import {
  OrganizationUrlControllerApi,
  QueryOrganizationUrlsRequest
} from '@rallycry/api-suite-typescript/dist/apis/OrganizationUrlControllerApi'
import { NetworkUrlUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/NetworkUrlUpdateCommand'
import { OrganizationUrlCreateCommand } from '@rallycry/api-suite-typescript/dist/models/OrganizationUrlCreateCommand'
import { useOrganization } from '../../entity/organization/useOrganization'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useOrganizationMeta } from './useOrganizationMeta'
import { useController } from '@/core/hooks/useSWRApi'

export const useOrganizationUrls = (
  options?: EntityOptions<QueryOrganizationUrlsRequest>
) => {
  const { organization } = useOrganization()
  const { ctrl } = useController(OrganizationUrlControllerApi)
  const { read: meta } = useOrganizationMeta()

  const parentId = organization?.id!

  const entity = useQueryEntity({
    key: 'useOrganizationUrls',
    paused: !parentId,
    ...options,
    suspense: false,
    request: { parentId, ...options?.request },
    metas: [meta],
    read: (req, { root }) =>
      ctrl({ metas: { root } }).queryOrganizationUrls(req),
    create: (cmd: OrganizationUrlCreateCommand) =>
      ctrl().createOrganizationUrl({
        parentId,
        OrganizationUrlCreateCommand: cmd
      }),
    update: (id, cmd: NetworkUrlUpdateCommand) =>
      ctrl().updateOrganizationUrl({ id, NetworkUrlUpdateCommand: cmd }),
    remove: id => ctrl().removeOrganizationUrl({ id })
  })

  return { ...entity, urls: entity.flat }
}

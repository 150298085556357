import { useParams } from 'next/navigation'
import { useRouteParamOverride } from '@/components/providers/site/RouteParamOverrideProvider'

export type RouteParams = {
  slug?: string
  model?: string
  documentId?: string
  activityId?: string
  bracketId?: string
  communityId?: string
  competitionId?: string
  eventId?: string
  matchId?: string
  newsId?: string
  org?: string
  rallyId?: string
  entryId?: string
  teamId?: string
  squadId?: string
  userId?: string
  responseId?: string
  formId?: string
  page?: string
  id?: string
  step?: string
}

export const useParsedParam = (param: keyof RouteParams) => {
  const overrides = useRouteParamOverride()
  const params = useParams()
  const value = params?.[param] as string
  const override = overrides[param] || value
  const parsed = parseInt(override || '', 10)
  return isNaN(parsed) ? 0 : parsed
}

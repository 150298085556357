import { Theme } from '@mui/material/styles'

export const fontFamily = {
  htmlFontSize: 16,
  h1: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    fontFamily: 'var(--font-poppins)',
    fontWeight: 700,
    fontStyle: 'normal'
  },
  h2: {
    fontSize: '1.375rem',
    lineHeight: '1.65rem',
    fontFamily: 'var(--font-poppins)',
    fontWeight: 600,
    fontStyle: 'normal'
  },
  h3: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontFamily: 'var(--font-poppins)',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  h4: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontFamily: 'var(--font-poppins)',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  h5: false,
  h6: false,
  body0: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '0.9',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  subtitle1: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 400,
    fontStyle: 'normal',
    textTransform: 'uppercase'
  },
  subtitle2: {
    fontSize: '0.875rem',
    lineHeight: '1.05rem',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 400,
    fontStyle: 'normal'
  },
  button: {
    fontSize: '1.0rem',
    lineHeight: '1.2rem',
    fontFamily: 'var(--font-roboto)',
    fontWeight: 400,
    fontStyle: 'normal'
  }
}

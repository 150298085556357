import { IconProp } from '@fortawesome/fontawesome-svg-core'
import MenuItem from '@mui/material/MenuItem'
import { Select } from 'formik-mui'
import { orderBy } from 'lodash-es'
import { IconProps } from '@/style/icon-library'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { RcIcon } from '@/components/atoms/RcIcon'

export const RcIconPicker = ({
  name,
  label,
  fabOnly
}: {
  name: string
  label: React.ReactNode
  fabOnly?: boolean
}) => {
  const sorted = orderBy(
    IconProps.filter(it => (fabOnly ? it[0] === 'fab' : true)),
    it => it[1]
  )

  return (
    <LabeledField
      component={Select}
      name={name}
      variant='outlined'
      label={label}
      fullWidth
    >
      {sorted.map(it => {
        const iconKey = `${it[0]}-${it[1]}`
        return (
          <MenuItem key={iconKey} value={iconKey}>
            <RcIcon icon={[it[0], it[1]] as IconProp} size='xs' mr={2} />{' '}
            {iconKey}
          </MenuItem>
        )
      })}
    </LabeledField>
  )
}

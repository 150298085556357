import { CommunityControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CommunityControllerApi'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useCommunityId } from './useCommunityId'
import { useController } from '@/core/hooks/useSWRApi'

export const useCommunityMeta = (options?: EntityOptions) => {
  const communityId = useCommunityId(options)
  const { ctrl } = useController(CommunityControllerApi)

  const entity = useReadEntity({
    key: 'useCommunityMeta',
    idOrKey: communityId,
    ...options,
    read: req => ctrl().readCommunityMeta(req)
  })

  return { ...entity, meta: entity.read.data }
}

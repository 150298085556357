import { EntityOptions } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'

// gets community id from route params, preferring squad id
export const useCommunityId = (options?: EntityOptions) => {
  const squadId = useParsedParam('squadId')
  const cid = useParsedParam('communityId')
  const communityId = Number(options?.idOrKey || squadId || cid)
  return communityId
}

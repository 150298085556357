import React, { createContext, use, useContext } from 'react'
import { Initial } from '@/core/base-provider-state'
import { RouteParams } from '@/core/hooks/useRouteParams'

/**
 * Enables overrides of useParsedParams values without a route.
 */
export const useRouteParamOverride = () => useContext(RouteParamOverrideContext)
export const RouteParamOverrideContext = createContext(Initial as RouteParams)
export const RouteParamOverrideProvider = ({
  children,
  context,
  ...props
}: {
  context: RouteParams
  children?: React.ReactNode
}) => {
  const parent = useRouteParamOverride()
  return (
    <RouteParamOverrideContext.Provider
      value={{ ...parent, ...context }}
      {...props}
    >
      {children}
    </RouteParamOverrideContext.Provider>
  )
}

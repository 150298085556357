import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { snakeCase } from 'lodash-es'
import dynamic from 'next/dynamic'
import { RcSelect } from '@/components/molecules/input/RcSelect'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import { RcToggle } from '@/components/molecules/input/RcToggle'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { PageForm } from '@/components/organisms/page/PageForm'
import {
  DEFAULT_ORG_FEATURES,
  FeatureCategory,
  FeatureInput,
  useFeatures
} from '@/components/providers/site/FeatureProvider'
import { useOrganization } from '@/entity/organization/useOrganization'

const RcFileInput = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcFileInput')).RcFileInput,
  { ssr: false, loading: () => <></> }
)

export const OrganizationFeatures = ({
  children,
  category,
  title,
  subtitle
}: {
  children?: React.ReactNode
  category: FeatureCategory
  title?: React.ReactNode
  subtitle?: React.ReactNode
}) => {
  return (
    <Stack spacing={10}>
      <SectionHeader title={title} subtitle={subtitle} />
      {children}
      {Object.keys(DEFAULT_ORG_FEATURES)
        .filter(feat => {
          const template =
            DEFAULT_ORG_FEATURES[feat as keyof typeof DEFAULT_ORG_FEATURES]

          return (
            template?.category === category ||
            template?.input === FeatureInput.None
          )
        })
        .map(it => (
          <RenderFeatureInput key={it} feat={it} />
        ))}
    </Stack>
  )
}

export const RenderFeatureInput = ({ feat }: { feat: string }) => {
  const { organization } = useOrganization()
  const features = useFeatures()

  const label = (it: string) =>
    snakeCase(it)?.replaceAll('_', ' ')?.replace('feat', '')?.replace('cfg', '')

  const template =
    DEFAULT_ORG_FEATURES[feat as keyof typeof DEFAULT_ORG_FEATURES]
  const featureKey = feat as keyof typeof features
  const current = features[featureKey]

  return {
    toggle: () => (
      <PageForm
        key={feat}
        title={label(feat)}
        description={template.description}
        sideChild
      >
        <RcToggle
          property={featureKey}
          source={features!}
          update={() => features.toggleFeat(feat)}
        />
      </PageForm>
    ),
    text: () => (
      <RcTextInput
        key={feat}
        label={label(feat)}
        description={template.description}
        property={featureKey}
        source={features}
        update={val => features.updateCfg(feat, val[featureKey])}
      />
    ),
    json: () => (
      <RcTextInput
        key={feat}
        label={label(feat)}
        description={template.description}
        property='json'
        source={{
          json: JSON.stringify(current) as any
        }}
        update={val => features.updateCfg(feat, JSON.parse(val.json))}
        multiline
        json
      />
    ),
    numeric: () => (
      <RcTextInput
        key={feat}
        label={label(feat)}
        description={template.description}
        property={featureKey}
        source={features}
        update={val => features.updateCfg(feat, val[featureKey])}
        number
      />
    ),
    select: () => (
      <RcSelect
        key={feat}
        label={label(feat)}
        description={template.description}
        property={featureKey}
        source={features}
        update={val => features.updateCfg(feat, val[featureKey])}
        allowNone={false}
      >
        {(template as any)?.options?.map((opt: string) => {
          return (
            <MenuItem key={opt} value={opt}>
              {opt}
            </MenuItem>
          )
        })}
      </RcSelect>
    ),
    image: () => {
      const isAvatar = featureKey?.toLowerCase().includes('avatar')
      const isBanner = featureKey?.toLowerCase().includes('banner')
      return (
        <Box>
          <RcTextInput
            key={feat}
            label={label(feat)}
            description={template.description}
            property={featureKey}
            source={features}
            update={val => features.updateCfg(feat, val[featureKey])}
          />
          <Box
            sx={{
              mt: 3,
              width: isAvatar ? 200 : '100%',
              aspectRatio: isAvatar ? 1 : isBanner ? 4.8 : undefined
            }}
          >
            <RcFileInput
              saveAs={`feat-${featureKey}-${organization?.id}`}
              source={(features[featureKey] as string) || ''}
              onComplete={async url => features.updateCfg(feat, url)}
              onClear={async () => features.updateCfg(feat, '')}
              circle={isAvatar}
              aspectRatio={isAvatar ? 1 : isBanner ? 4.8 : undefined}
            />
          </Box>
        </Box>
      )
    },
    none: () => null
  }[template.input]()
}
